import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/forParents',
    name: 'forParents',
    alias: '/pro-rodice',
    // route level code-splitting
    // this generates a separate chunk (search.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "forparents" */ '../views/ForParentsView.vue')
  },
  {
    path: '/about',
    name: 'about',
    alias: '/veletrh',
    // route level code-splitting
    // this generates a separate chunk (search.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/ukraine',
    name: 'ukraine',
    // route level code-splitting
    // this generates a separate chunk (search.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ukraine" */ '../views/UkraineView.vue')
  },
  {
    path: '/download',
    name: 'download',
    alias: '/aplikace',
    // route level code-splitting
    // this generates a separate chunk (search.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "download" */ '../views/DownloadView.vue')
  },

  // Private views
  {
    path: '/tutor',
    name: 'tutor',
    alias: '/pruvodce',
    // route level code-splitting
    // this generates a separate chunk (search.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tutor" */ '../views/TutorView.vue')
  },
   // Private views
   {
    path: '/tutornew',
    name: 'tutornew',
    alias: '/prijimacky',
    // route level code-splitting
    // this generates a separate chunk (search.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tutornew" */ '../views/TutorNewView.vue')
  },
  {
    path: '/search',
    name: 'search',
    alias: '/hledat',
    // route level code-splitting
    // this generates a separate chunk (search.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "search" */ '../views/SearchView.vue')
  },
  {
    path: '/welcome',
    name: 'welcome',
    alias: '/vitej',
    // route level code-splitting
    // this generates a separate chunk (search.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "welcome" */ '../views/WelcomeView.vue')
  },
  {
    path: '/students',
    name: 'students',
    alias: '/zak',
    // route level code-splitting
    // this generates a separate chunk (search.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "students" */ '../views/StudentsView.vue')
  },
  {
    path: '/parents',
    name: 'parents',
    alias: '/rodic',
    // route level code-splitting
    // this generates a separate chunk (search.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "parents" */ '../views/ParentsView.vue')
  },
  {
    path: '/school',
    name: 'school',
    alias: '/skola',
    // route level code-splitting
    // this generates a separate chunk (search.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "school" */ '../views/SchoolView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  }
})

export default router
