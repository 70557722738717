<template>
    <!-- External text -->
    <div>
        <p class="py-2" v-for="p in text">{{ p }}</p>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'ExternalText',
    props: {
        url: String
    },
    data() {
        return {
            text: 'Načítám...'
        }
    },
    mounted() {
        axios.get(this.url).then(res => this.text = res.data.split('$')).catch(error => console.log(error));
    }
}
</script>
