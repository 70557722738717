<template>
    <!-- Hlavička -->
    <HeaderBar></HeaderBar>
    <!-- Hero -->
    <div style="background-position: right top, left;" v-if="$route.name == 'home'" class="bg-no-repeat bg-[url(/pattern_mobile.png)] md:bg-[url(/hero_right.png),_url()] xl:bg-[url(/hero_right.png),_url(/hero_left.png)] bg-secondary-light md:bg-contain md:bg-no-repeat pb-4">
        <div class="md:max-w-xl lg:mx-auto lg:max-w-4xl">
            <h1 class="text-3xl lg:text-4xl lg:max-w-xl p-8 font-bold text-primary sm:text-left sm:p-8 sm:pt-16">Najdi střední školu,<br class="hidden sm:block"> která tě bude bavit</h1>
            <p class="text-lg font-medium text-secondary-text p-8 pb-0 sm:pb-8 pt-0 sm:text-left sm:p-8 sm:pt-0 lg:max-w-[65%] opacity-70">Veletrh a aplikace <strong>Schola Pragensis</strong> vás provede výběrem střední školy. Udělejte si přehled <br class="block sm:hidden"> o stovkách škol na jednom místě <strong>23. 11. – 25. 11. 2023</strong> <br>v Kongresovém centru Praha. </p>
            <SearchBar @s-change="search" :wait="true"></SearchBar>
        </div>
    </div>
    <!-- Router -->
    <router-view></router-view>
    <!-- Footer -->
    <FooterBar></FooterBar>  
</template>

<script>
import HeaderBar from './components/HeaderBar.vue';
import SearchBar from './components/SearchBar.vue';
import FooterBar from './components/FooterBar.vue';
import ExternalText from './components/ExternalText.vue';

export default {
    name: 'App',
    data() {
        return {
           phrase: '',
           searchObject: null
        }
    },
    components: {
        HeaderBar,
        SearchBar,
        FooterBar,
        ExternalText
    },
    methods: {
        handleChange(e) {
            this.searchObject = e;
        },
        search(e) {
            // named route with params to let the router build the url
            this.$router.push({ name: 'search', query: { s: JSON.stringify(e) }});
        }
    }
}
</script>
